
import Vue from 'vue';
import { mapGetters } from 'vuex';
import InlineSvg from 'vue-inline-svg';
import { LocaleMessage } from 'vue-i18n';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomClipboardText from '@/components/atoms/AtomClipboardText.vue';
import AtomButton, { ButtonVariant, ButtonSize } from '@/components/atoms/AtomButton.vue';
import MoleculeDropdown from '@/components/molecules/dropdown/MoleculeDropdown.vue';
import { toast, ToastVariants } from '@/components/atoms/AtomToast';
import { shareRideWithFollowers } from '@/api/community/communityApi';
import BaseResponse from '@/models/IBaseResponse';

enum MenuState {
  MAIN,
  SHARE_OUTWARD,
  SHARE_RETURN,
  SHARE_BOTH,
}

export default Vue.extend({
  name: 'OrganismShareRideDropdown',
  components: {
    InlineSvg,
    AtomSvgIcon,
    AtomButton,
    AtomText,
    AtomClipboardText,
    MoleculeDropdown,
  },
  props: {
    outwardInvite: {
      type: String,
      required: true,
    },
    outwardIntentId: {
      type: String,
      required: true,
    },
    returnInvite: {
      type: String,
      required: true,
    },
    returnIntentId: {
      type: String,
      required: false,
    },
    sequenceId: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      menuState: MenuState.MAIN,
      MenuState,
      ButtonVariant,
      ButtonSize,
      selectedMenuId: MenuState.SHARE_BOTH as number|null,
      menu: [],
    };
  },
  methods: {
    toast,
    setSelectedMenu(id: number|null) {
      if (id) this.selectedMenuId = id;
      else this.selectedMenuId = null;
    },
    makeToast(message: string, variant = ToastVariants.SUCCESS) {
      toast(this.$bvToast, message, variant);
    },
    async shareWithFollowers() {
      if (!this.user) return;
      const calls: Promise<BaseResponse>[] = [];
      if (this.selectedMenuId === MenuState.SHARE_OUTWARD) {
        calls.push(shareRideWithFollowers(this.user.follower.communityId, this.outwardIntentId));
      } else if (this.selectedMenuId === MenuState.SHARE_RETURN) {
        calls.push(shareRideWithFollowers(this.user.follower.communityId, this.returnIntentId));
      } else if (this.selectedMenuId === MenuState.SHARE_BOTH) {
        calls.push(shareRideWithFollowers(this.user.follower.communityId, this.outwardIntentId));
        calls.push(shareRideWithFollowers(this.user.follower.communityId, this.returnIntentId));
      }

      const [status] = await Promise.all(calls);
      if (status.status === 201) {
        this.makeToast('Fahrt erfolgreich geteilt');
      }
    },
  },
  computed: {
    ...mapGetters('user', ['user']),
    hasSequence(): boolean {
      return !!this.sequenceId;
    },
    getHeading(): LocaleMessage|string {
      if (!this.hasSequence) return '';

      const { title } = this.getListItems.find((m) => m.id === this.selectedMenuId) || {};
      if (title) return this.$t(title);

      return '';
    },
    // TODO: A good idea to make this component generic and to get the menu items as props.
    getListItems(): any[] {
      return [
        {
          id: MenuState.SHARE_OUTWARD,
          title: this.$t('dictionary.ride.outward'),
          icon: 'icons/icon-circle-arrow-right.svg',
          action: this.setSelectedMenu,
          active: this.hasSequence,
        },
        {
          id: MenuState.SHARE_RETURN,
          title: this.$t('dictionary.ride.return'),
          icon: 'icons/icon-circle-arrow-left.svg',
          action: this.setSelectedMenu,
          active: this.hasSequence,
        },
      ];
    },
  },
  mounted() {
    // this.$root.$on('bv::dropdown::show', () => {
    //   this.selectedMenuId = this.hasSequence ? null : MenuState.SHARE_OUTWARD;
    // });
  },
});
