
import Vue, { PropType } from 'vue';
import { mapGetters } from 'vuex';
import moment from 'moment';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomSixtBanner from '@/components/atoms/AtomSixtBanner.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import MoleculeCard, { CardVariant } from '@/components/molecules/cards/MoleculeCard.vue';
import MoleculeCardUser from '@/components/molecules/cards/MoleculeCardUser.vue';
import MoleculeModal from '@/components/molecules/modal/MoleculeModal.vue';
import OrganismRideDetailsCard, {
  RideDetailsCardVariant,
} from '@/components/organisms/home/OrganismRideDetailsCard.vue';
import RideIntentModel from '@/models/ride-intent/RideIntentModel';
import RolesEnum from '@/enums/rides/RolesEnum';
import { hasFeature } from '@/services/feature/FeatureService';
import FeatureEnum from '@/enums/settings/FeatureEnum';
import { getParkingIcon, getParkingStatus, showParkingInformation } from './OrganismDetailsService';

export default Vue.extend({
  name: 'OrganismIntentDetails',
  components: {
    AtomText,
    AtomSvgIcon,
    AtomButton,
    MoleculeCard,
    MoleculeCardUser,
    MoleculeModal,
    OrganismRideDetailsCard,
    AtomSixtBanner,
  },
  props: {
    intent: {
      type: Object as PropType<RideIntentModel>,
      required: true,
    },
    isInPast: {
      type: Boolean,
    },
  },
  data: () => ({
    ButtonVariant,
    RideDetailsCardVariant,
    RolesEnum,
    CardVariant,
    hasAlternatives: true,
    currTime: moment(),
  }),
  computed: {
    ...mapGetters('user', ['features', 'b2cFree']),
    showParkingDetails(): boolean {
      return showParkingInformation(
        this.intent.role,
        this.features,
        !!this.intent.destination.providesParkingPlace,
      );
    },
    hasInfo(): boolean {
      return !!this.intent.parkingSpace?.comment;
    },
    showSixtBanner(): boolean {
      return this.b2cFree || hasFeature(this.features, FeatureEnum.SIXT_BANNER);
    },
  },
  methods: {
    hasFeature,
    getParkingIcon,
    getParkingStatus,
  },
  mounted() {
    setTimeout(() => {
      this.hasAlternatives = false;
    }, 5000);
  },
});
