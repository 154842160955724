
import moment from 'moment';
import Vue from 'vue';
import { mapGetters, mapActions } from 'vuex';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import OrganismIntentDetails from '@/components/organisms/home/ride-details/OrganismIntentDetails.vue';
import OrganismRideDetails from '@/components/organisms/home/ride-details/OrganismRideDetails.vue';
import OrganismPageSubHeader from '@/components/organisms/page-header/OrganismPageSubHeader.vue';
import OrganismOptionsDropdown from '@/components/organisms/home/OrganismOptionsDropdown.vue';
import OrganismShareRideDropdown from '@/components/organisms/home/OrganismShareRideDropdown.vue';

import { formatCurrency } from '@/common/CurrencyFormatter';
import { formatToLongDate } from '@/common/DateFormatter';
import MoleculeModal from '@/components/molecules/modal/MoleculeModal.vue';
import RouteModel from '@twogo/geolocation/src/lib/models/routing/RouteModel';
import RolesEnum from '@/enums/rides/RolesEnum';
import { getIntentById, getIntentRecurrenceById } from '@/api/ride-intent/rideIntentApi';
import RideIntentModel from '@/models/ride-intent/RideIntentModel';
import { RideModel } from '@/models/ride/RideModel';
import EventTypeEnum from '@/enums/rides/EventsTypeEnum';
import isEnvReady, { Features } from '@/services/utilities/EnvFeatureService';

const MODAL_ID_PAYPAL = 'tg-modal--payment-not-avaliable';

export default Vue.extend({
  name: 'TemplateRideDetails',
  components: {
    AtomButton,
    AtomText,
    MoleculeModal,
    OrganismPageSubHeader,
    OrganismIntentDetails,
    OrganismOptionsDropdown,
    OrganismRideDetails,
    OrganismShareRideDropdown,
  },
  data: () => ({
    MODAL_ID_PAYPAL,
    Features,
    RolesEnum,
    ButtonVariant,
    intent: {} as RideIntentModel,
    ride: {} as RideModel,
    routes: [] as RouteModel[],
    recurringSequenceId: null as string | null,
    url: '',
  }),
  methods: {
    ...mapActions('rides', ['fetchRides']),
    formatCurrency,
    formatToLongDate,
    isEnvReady,
    getClasses() {
      return {
        'payment-button': true,
        'ride-started': this.hasStarted,
      };
    },
    goToPayPal() {
      if (this.hasStarted) {
        window.open(this.url, '_blank');
      } else {
        this.$bvModal.show(this.MODAL_ID_PAYPAL);
      }
    },
    backToHome(eventType: EventTypeEnum) {
      this.$router.push({
        name: 'Home',
        params: { eventType },
      });
    },
  },
  computed: {
    ...mapGetters('rides', {
      getCachedIntentById: 'getIntentById',
      getCachedRideById: 'getRideById',
    }),
    ...mapGetters('user', {
      userId: 'getUserId',
    }),
    isInPast(): boolean {
      return moment().diff(this.intent.latestArrival) > 0;
    },
    hasStarted(): boolean {
      return moment().diff(this.intent.earliestDeparture) > 0;
    },
    hasPaypalLink(): boolean {
      return !!this.ride.payment?.paypalDonation.url;
    },
  },
  async mounted() {
    window.scroll(0, 0);
    const { id, justUpdated } = this.$route.params;
    const cachedIntent = this.getCachedIntentById(id) as RideIntentModel;

    if (!justUpdated && cachedIntent) {
      this.intent = cachedIntent;
    } else {
      const { data: intent, status } = await getIntentById(id);
      if (intent && status === 200) {
        this.intent = intent;
        await this.fetchRides(this.userId);
      } else {
        this.$router.push({ name: 'Home' });
        return;
      }
    }

    this.ride = (this.getCachedRideById(id) as RideModel) || {};

    if (this.hasPaypalLink) {
      this.url = this.ride.payment?.paypalDonation.url || '';
    }

    if (this.intent.recurringIntentId) {
      const { data: recurringIntent } = await getIntentRecurrenceById(
        this.intent.recurringIntentId,
      );
      if (recurringIntent) {
        this.recurringSequenceId = recurringIntent.sequenceId;
      }
    }
  },
});
