
import Vue from 'vue';
import { mapActions } from 'vuex';
import InlineSvg from 'vue-inline-svg';
import { LocaleMessage } from 'vue-i18n';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomButton, { ButtonVariant, ButtonSize } from '@/components/atoms/AtomButton.vue';
import MoleculeDropdown from '@/components/molecules/dropdown/MoleculeDropdown.vue';
import { toast, ToastVariants } from '@/components/atoms/AtomToast';

enum MenuState {
  MAIN,
  EDIT,
  DUPLICATE,
  DELETE,
}

export default Vue.extend({
  name: 'OrganismOptionsDropdown',
  components: {
    InlineSvg,
    AtomSvgIcon,
    AtomButton,
    MoleculeDropdown,
  },
  props: {
    hasSequence: {
      type: Boolean,
      default: false,
    },
    hasSeries: {
      type: Boolean,
      default: false,
    },
    intentId: {
      type: String,
      required: false,
    },
    sequenceId: {
      type: String,
      required: false,
    },
    recurringIntentId: {
      type: String,
      required: false,
    },
    recurringSequenceId: {
      type: String,
      required: false,
    },
  },
  data: () => ({
    menuState: MenuState.MAIN,
    MenuState,
    ButtonVariant,
    ButtonSize,
    selectedMenuId: null as number|null,
    menu: [],
  }),
  methods: {
    ...mapActions('rideIntent', ['deleteIntentById', 'deleteRoundtripById', 'deleteRecurrencesById', 'deleteRecurringRoundtripById']),
    // toast,
    setSelectedMenu(id: number|null) {
      if (id) this.selectedMenuId = id;
      else this.selectedMenuId = null;
    },
    makeToast(message: string, variant = ToastVariants.SUCCESS) {
      toast(this.$bvToast, message, variant);
    },
    redirect() {
      setTimeout(() => {
        this.$router.push({ name: 'Home' });
      }, 2000);
    },
  },
  computed: {
    isSingleIntent(): boolean {
      return !this.hasSequence && !this.hasSeries;
    },
    getHeading(): LocaleMessage {
      const { title } = this.getListItems.find((m) => m.id === this.selectedMenuId) || {};
      if (title) return this.$t(title);

      return this.$t('dictionary.settings');
    },
    getMenu(): any[] {
      const { subMenu } = this.getListItems.find((m) => m.id === this.selectedMenuId) || {};
      if (subMenu) return subMenu;

      return this.getListItems;
    },
    // TODO: A good idea to make this component generic and to get the menu items as props.
    getListItems(): any[] {
      const { isSingleIntent } = this;
      return [
        {
          id: 1,
          title: this.$t('dictionary.edit'),
          icon: 'icons/icon-edit.svg',
          action: isSingleIntent
            ? () => this.$router.push({ path: `/${this.$i18n.locale}/edit/ride/${this.intentId}` })
            : () => this.setSelectedMenu(1),
          active: true,
          subMenu: [
            {
              title: this.$t('dictionary.ride.single'),
              icon: 'icons/icon-circle-arrow-right.svg',
              action: () => this.$router.push({ path: `/${this.$i18n.locale}/edit/ride/${this.intentId}` }),
              active: !this.hasSequence,
            },
            {
              title: this.$t('dictionary.ride.both'),
              icon: 'icons/icon-circle-both-directions.svg',
              action: () => this.$router.push(
                {
                  path: `/${this.$i18n.locale}/edit/ride/${this.intentId}`,
                  query: { sequenceId: this.sequenceId, withSequence: 'true' },
                },
              ),
              active: this.hasSequence,
            },
            {
              title: this.$t('dictionary.ride.series'),
              icon: 'icons/icon-repeat.svg',
              action: () => this.$router.push(
                { path: `/${this.$i18n.locale}/edit/recurrences/${this.intentId}${this.hasSequence ? '?withSequence=true' : ''}` },
              ),
              active: this.hasSeries,
            },
          ],
        },
        {
          id: 2,
          title: this.$t('dictionary.delete'),
          icon: 'icons/icon-trash.svg',
          danger: true,
          action: isSingleIntent
            ? () => this.deleteIntentById(this.intentId).then(() => {
              this.makeToast(this.$t('rideIntent.deleteRideIntentSuccess').toString(), ToastVariants.SUCCESS);
              this.redirect();
            }).catch(() => {
              this.makeToast(this.$t('rideIntent.deleteRideIntentFailed').toString(), ToastVariants.DANGER);
            })
            : () => this.setSelectedMenu(2),
          active: true,
          subMenu: [
            {
              title: this.$t('dictionary.ride.single'),
              icon: 'icons/icon-circle-arrow-right.svg',
              action: () => this.deleteIntentById(this.intentId).then(() => {
                this.makeToast(this.$t('rideIntent.deleteRideIntentSuccess').toString(), ToastVariants.SUCCESS);
                this.redirect();
              }).catch(() => {
                this.makeToast(this.$t('rideIntent.deleteRideIntentFailed').toString(), ToastVariants.DANGER);
              }),
              active: true,
            },
            {
              title: this.$t('dictionary.ride.both'),
              icon: 'icons/icon-circle-both-directions.svg',
              action: () => this.deleteRoundtripById(this.sequenceId).then(() => {
                this.makeToast(this.$t('rideIntent.deleteRideIntentSuccess').toString(), ToastVariants.SUCCESS);
                this.redirect();
              }).catch(() => {
                this.makeToast(this.$t('rideIntent.deleteRideIntentFailed').toString(), ToastVariants.DANGER);
              }),
              active: (this.hasSequence && !this.hasSeries) || (this.hasSequence && this.hasSeries),
            },
            {
              title: this.$t('dictionary.ride.series'),
              icon: 'icons/icon-repeat.svg',
              action: () => this.deleteRecurrencesById(this.recurringIntentId).then(() => {
                this.makeToast(this.$t('rideIntent.deleteRideIntentSuccess').toString(), ToastVariants.SUCCESS);
                this.redirect();
              }).catch(() => {
                this.makeToast(this.$t('rideIntent.deleteRideIntentFailed').toString(), ToastVariants.DANGER);
              }),
              active: this.hasSeries && !this.hasSequence,
            },
            {
              title: this.$t('dictionary.ride.series'),
              icon: 'icons/icon-repeat.svg',
              action: () => this.deleteRecurringRoundtripById(this.recurringSequenceId).then(() => {
                this.makeToast(this.$t('rideIntent.deleteRideIntentSuccess').toString(), ToastVariants.SUCCESS);
                this.redirect();
              }).catch(() => {
                this.makeToast(this.$t('rideIntent.deleteRideIntentFailed').toString(), ToastVariants.DANGER);
              }),
              active: this.hasSeries && this.hasSequence,
            },
          ],
        },
      ];
    },
  },
  mounted() {
    this.$root.$on('bv::dropdown::hide', () => {
      this.selectedMenuId = null;
    });
  },
});
