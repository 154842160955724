
import Vue from 'vue';
import InlineSvg from 'vue-inline-svg';
import MoleculeDropdown from '../molecules/dropdown/MoleculeDropdown.vue';

export default Vue.extend({
  name: 'AtomSixtBanner',
  components: {
    InlineSvg,
    MoleculeDropdown,
  },
});
