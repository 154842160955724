
import Vue, { PropType } from 'vue';
import AtomBadge, { BadgeVariant } from '@/components/atoms/AtomBadge.vue';
import MoleculeModal from '@/components/molecules/modal/MoleculeModal.vue';
import AtomCircularIcon, { CircularIconVariant, CircularIconSize } from '@/components/atoms/AtomCircularIcon.vue';
import { RideVehicleModel } from '@/models/ride/RideModel';
import { fetchImage } from '@/api/user/userApi';

export default Vue.extend({
  name: 'OrganismCarModal',
  components: {
    AtomBadge,
    AtomCircularIcon,
    MoleculeModal,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    car: {
      type: Object as PropType<RideVehicleModel>,
      default: () => ({} as RideVehicleModel),
    },
  },
  data: () => ({
    vehicle: {} as RideVehicleModel|{},
    BadgeVariant,
    CircularIconVariant,
    CircularIconSize,
  }),
  async mounted() {
    this.vehicle = this.car.vehicleId
      ? { ...this.car, decodedImage: await fetchImage(this.car.imageURL) }
      : {};
  },
});
