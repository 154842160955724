
import Vue from 'vue';
import { toast, ToastVariants } from '@/components/atoms/AtomToast';

export default Vue.extend({
  name: 'AtomClipboardText',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  methods: {
    copyToClipboard() {
      navigator.clipboard.writeText(this.text);
      toast(this.$bvToast, 'In Zwischenablage kopiert', ToastVariants.SUCCESS);
    },
  },
});
