
import Vue, { PropType } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import { LocaleMessage } from 'vue-i18n';
import moment from 'moment';
import AtomAvatar from '@/components/atoms/AtomAvatar.vue';
import AtomText from '@/components/atoms/AtomText.vue';
import AtomSvgIcon from '@/components/atoms/AtomSvgIcon.vue';
import AtomBadge, { BadgeVariant } from '@/components/atoms/AtomBadge.vue';
import AtomButton, { ButtonVariant } from '@/components/atoms/AtomButton.vue';
import AtomActionSelector from '@/components/atoms/AtomActionSelector.vue';
import AtomStackedCardList from '@/components/atoms/AtomStackedCards/AtomStackedCardList.vue';
import AtomStackedCardListItem from '@/components/atoms/AtomStackedCards/AtomStackedCardListItem.vue';
import { toast } from '@/components/atoms/AtomToast';
import MoleculeCard, { CardVariant } from '@/components/molecules/cards/MoleculeCard.vue';
import MoleculeCardUser from '@/components/molecules/cards/MoleculeCardUser.vue';
import MoleculeHereMap from '@/components/molecules/maps/MoleculeHereMap.vue';
import MoleculeModal from '@/components/molecules/modal/MoleculeModal.vue';
import OrganismRideDetailsCard, {
  RideDetailsCardVariant,
} from '@/components/organisms/home/OrganismRideDetailsCard.vue';
import OrganismCarModal from '@/components/organisms/home/OrganismCarModal.vue';
import OrganismUsersRatingModal from '@/components/organisms/ratings/OrganismUsersRatingModal.vue';
import { RideModel, RideUserModel } from '@/models/ride/RideModel';

import RolesEnum from '@/enums/rides/RolesEnum';
import TransportModeEnum from '@/enums/rides/TransportModeEnum';
import RoutingModeEnum from '@twogo/geolocation/src/lib/enums/RoutingModeEnum';
import FeaturesEnum from '@/enums/settings/FeatureEnum';

import BaseMarkerModel from '@twogo/here-maps/src/lib/models/BaseMarkerModel';
import { createMarkerBase } from '@twogo/here-maps/src/lib/HereMapsService';

import locationPin from '@/assets/images/icons/icon-pin-start.svg';
import locationFlag from '@/assets/images/icons/icon-pin-flag.svg';

import RouteModel from '@twogo/geolocation/src/lib/models/routing/RouteModel';
import RouteReqModel from '@twogo/geolocation/src/lib/models/routing/requests/RouteReqModel';
import { route } from '@/services/geolocation/geolocationService';

import { formatCurrency } from '@/common/CurrencyFormatter';
import { hasFeature } from '@/services/feature/FeatureService';

import { skipUserRating, rateUser, suggestAnnotations } from '@/api/ratings/ratingsApi';
import { sortString } from '@/services/utilities/sort';
import RideIntentModel from '@/models/ride-intent/RideIntentModel';

import { showParkingInformation, getParkingIcon, getParkingStatus } from './OrganismDetailsService';

enum AnimationState {
  START,
  SLIDE_IN,
  SLIDE_OUT,
}

export default Vue.extend({
  name: 'OrganismRideDetails',
  components: {
    AtomText,
    AtomActionSelector,
    AtomSvgIcon,
    AtomBadge,
    AtomButton,
    AtomStackedCardList,
    AtomStackedCardListItem,
    AtomAvatar,
    MoleculeCard,
    MoleculeCardUser,
    MoleculeHereMap,
    MoleculeModal,
    OrganismRideDetailsCard,
    OrganismCarModal,
    OrganismUsersRatingModal,
  },
  props: {
    ride: {
      type: Object as PropType<RideModel>,
      required: true,
    },
    intent: {
      type: Object as PropType<RideIntentModel>,
      required: true,
    },
  },
  data() {
    return ({
      routes: [] as RouteModel[],
      users: [] as RideUserModel[],
      animationState: AnimationState.START,
      currTime: moment(),
      RideDetailsCardVariant,
      RolesEnum,
      FeaturesEnum,
      CardVariant,
      BadgeVariant,
      ButtonVariant,
    });
  },
  computed: {
    ...mapGetters('user', ['getRideUser', 'getUserId', 'getOtherUsers', 'features']),
    ...mapGetters('rides', {
      unRatedRides: 'getGetUnratedRidesById',
      hasUnratedRides: 'hasUnratedRides',
      getIntentById: 'getIntentById',
    }),
    showParkingDetails(): boolean {
      return showParkingInformation(
        this.ride.role,
        this.features,
        !!this.intent.destination.providesParkingPlace,
      );
    },
    hasInfo(): boolean {
      return !!this.intent.parkingSpace?.comment;
    },
    unratedRideUsers(): RideUserModel[] {
      const { unratedUserIds } = this;
      return (this.getOtherUsers as RideUserModel[]).filter((u) => unratedUserIds.includes(u.userId));
    },
    unratedUserIds(): string[] {
      return this.unRatedRides(this.ride.matchId)
        .map((r) => r.userId)
        .sort(sortString);
    },
    isInPast(): boolean {
      return moment().diff(this.ride.destination.dateTime) > 0;
    },
    driverId(): string {
      return this.ride.sections[0].driverId;
    },
    isAnimationStateSlideIn(): boolean {
      return this.animationState === AnimationState.SLIDE_IN;
    },
    isAnimationStateSlideInOrOut(): boolean {
      return [AnimationState.SLIDE_IN, AnimationState.SLIDE_OUT].includes(this.animationState);
    },
    getAnimationChildCardClass(): string {
      if (this.animationState === AnimationState.SLIDE_OUT) {
        return 'animation--out';
      }
      return 'animation';
    },
    getRole(): LocaleMessage {
      if (this.ride.role === RolesEnum.DRIVER) {
        return this.$t('rideIntent.role.driver');
      }
      return this.$t('rideIntent.role.passenger');
    },
    isPassenger(): boolean {
      return this.ride.role === RolesEnum.PASSENGER;
    },
    // Matched ride functionality
    getDriver(): RideUserModel[] {
      const { driverId } = this;
      return this.users.filter((occupant) => occupant.userId === driverId);
    },
    getPassengers(): RideUserModel[] {
      const { driverId } = this;
      return this.users.filter((occupant) => occupant.userId !== driverId);
    },
    // Map functionality
    getMarkers(): BaseMarkerModel[] {
      if (!this.ride.origin) return [];
      const markers = [] as BaseMarkerModel[];

      markers.push(createMarkerBase(locationPin, this.ride.origin.location));
      this.ride.sections[0].stops.forEach(({ location }, index) => {
        markers.push(createMarkerBase(locationPin, location, index.toString()));
      });
      markers.push(createMarkerBase(locationFlag, this.ride.destination.location));

      return markers;
    },
  },
  methods: {
    ...mapActions('rides', ['fetchUnratedRides', 'removeRatedUser']),
    hasFeature,
    getParkingIcon,
    getParkingStatus,
    getNotRatedUserAvatarProps(user: RideUserModel): { imgSrc: string; name: string; company: string|null} {
      return {
        imgSrc: user.decodedImage,
        name: `${user.firstName} ${user.lastName}`,
        company: user.companyName,
      };
    },
    round(): string | number {
      const minutes = Math.round(this.ride.sections[0].route.secs / 60);
      if (minutes / 60 > 1) {
        const hours = Math.floor(minutes / 60);
        const minute = minutes % 60;
        return `${hours} ${this.$t('home.detailView.hourAbbreviation')} ${minute} `;
      }
      return minutes;
    },
    formatCurrency,
    calculateRoute(): void {
      if (!this.ride.origin) return;

      const queryModel = {
        transportMode: TransportModeEnum.CAR,
        origin: this.ride.origin.location,
        destination: this.ride.destination.location,
        routingMode: RoutingModeEnum.FAST,
        via: this.ride.sections[0].stops.map(({ location }) => location),
      } as RouteReqModel;

      route(queryModel).then(({ data }) => {
        if (data) {
          this.routes = data;
        }
      });
    },
    expandShrinkPassengers(): void {
      if (this.animationState === AnimationState.START) {
        this.animationState = AnimationState.SLIDE_IN;
      } else if (this.animationState === AnimationState.SLIDE_IN) {
        this.animationState = AnimationState.SLIDE_OUT;
        setTimeout(() => {
          this.animationState = AnimationState.START;
        }, 670);
      }
    },
    roleAssignment(user: RideUserModel): RideUserModel {
      return {
        ...user,
        role: user.userId === this.driverId ? RolesEnum.DRIVER : RolesEnum.PASSENGER,
      } as RideUserModel;
    },
    navigateToUser(id: string): void {
      if (id === this.getUserId) {
        this.$router.push({ name: 'Profile' });
      } else {
        this.$router.push({
          name: 'ProfileUser',
          params: { id },
        });
      }
    },
    async rateUser({ entity: { userId }, rating, annotations }): Promise<void> {
      await rateUser(
        {
          userId,
          matchId: this.ride.matchId,
          rating,
          annotations,
        },
        this.getUserId,
      );

      this.removeRatedUser({ userId, matchId: this.ride.matchId });
    },
    async skipUserRating({ entity: { userId } }): Promise<void> {
      await skipUserRating(
        {
          userId,
          matchId: this.ride.matchId,
        },
        this.getUserId,
      );

      this.removeRatedUser({ userId, matchId: this.ride.matchId });
    },
    async suggest(suggestedAnnotations: string[]): Promise<void> {
      await suggestAnnotations(suggestedAnnotations);
      toast(this.$bvToast, this.$t('ratings.modal_rate.suggestion_success').toString());
    },
  },
  mounted() {
    this.users = [...this.ride.users, this.getRideUser as RideUserModel].map(this.roleAssignment);
    this.calculateRoute();
    this.fetchUnratedRides(this.getUserId);
  },
  watch: {
    getRideUser(_, newVal) {
      this.users = [...this.ride.users, newVal].map(this.roleAssignment);
    },
    ride(_, newVal) {
      this.users = [...newVal.users, this.getRideUser].map(this.roleAssignment);
    },
  },
});
