import { render, staticRenderFns } from "./TemplateRideDetails.vue?vue&type=template&id=f8ac380e&scoped=true"
import script from "./TemplateRideDetails.vue?vue&type=script&lang=ts"
export * from "./TemplateRideDetails.vue?vue&type=script&lang=ts"
import style0 from "./TemplateRideDetails.vue?vue&type=style&index=0&id=f8ac380e&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8ac380e",
  null
  
)

export default component.exports